import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeZip from '../components/form/components/zip'
import { setField, setHomeCityState, clearHomeCityState } from '../state/action'
import { connect } from 'react-redux' 
import { navigate } from 'gatsby'
import  { Formik, Form } from 'formik'

const IndexPage = ({location, setField}) => (

  <Layout location={location}>
    <SEO title="Home - Horizon Health insurance" />
    <div className="container home-wrapper">
      <div className="flex-row">
        <div className="flex-large">
          <h2>Horizon Health</h2>
            {/* <p className="desktop">Get a Quote for a Health Plan Today</p> */}
            {/* <p className="desktop">for a Health Plan Today</p> */}
          <h3 className="home-subhead">Get a Quote for a Health Plan Today</h3>
            {/* <p>Now in all 50 states</p> */}
        </div>
        <div className="flex-large form-home-column">
          <h3>Get Your Free Quote</h3>
          <Formik
            initialValues={{zip: ""}}
            onSubmit={(values, actions) => {
                fetch(`https://api.zippopotam.us/us/${values.zip}`)
                .then(res => res.json().then(function(data) {
                  }).then(() => navigate('form'))
                )
                
                // actions.setSubmitting(false);
            }}
            render={(props) => (
              <Form className="zip-submit">
                  <HomeZip setField={setField} {...props} hideLabel />
                  <button className="primary-button" type="submit">Next</button>
              </Form >
            )}
          />
        </div>
      </div>
    </div>
  </Layout>

)

const mapStateToProps = ({formState}) => {
  return { 
    formState
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setField: field => dispatch( setField( field ) ),
    setHomeCityState: data => dispatch( setHomeCityState(data)),
    clearHomeCityState: () => dispatch( clearHomeCityState() )
  }
}

export default connect( mapStateToProps, mapDispatchToProps )( IndexPage )

